<template>
  <div>
    <a-modal @cancel="onClose" :visible="localVisible" :title="`Chuyển nhóm`">
      <a-alert :message="getMessage" type="info" style="margin-bottom:10px" />
      <a-row :gutter="8">
        <a-col :span="24">
          <label>Salon</label>
          <a-select
            style="width: 100%; margin: 10px 0 10px 0"
            showSearch
            :allowClear="true"
            v-model="data.salonId"
            @change="handleChangeSalon"
            placeholder="Vui lòng chọn salon"
            :filterOption="filterOptionSalon"
          >
            <a-select-option v-for="salon in user.listSalon" :key="salon.id">{{ salon.salonName }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <label>Nhóm STYNEXT</label>
          <a-select
            style="width: 100%; margin: 5px 0 5px 0"
            showSearch
            :allowClear="true"
            @select="selectStynextGroup"
            v-model="stynextGroupId"
            placeholder="Vui lòng chọn nhóm muốn chuyển tới"
            :disabled="!data.salonId || data.salonId < 0"
            :filterOption="filterOptionMember"
          >
            <a-select-option v-for="stynextGroup in stynextGroupOptions" :key="stynextGroup.id">
              {{
              ` Nhóm ${stynextGroup.id} (${
              stynextGroup.groupLeaderName ? stynextGroup.groupLeaderName : 'Không có trưởng nhóm'
              })`
              }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-alert
          v-if="localData.isGroupLeader && stynextGroupSelected && !isSelectedGroupLeader"
          :message="getMessageUpdateLevel"
          type="warning"
          style="margin: 10px 0 10px 0"
        />
        <a-col v-if="localData.isGroupLeader && stynextGroupSelected && !isSelectedGroupLeader">
          <label>Bậc STYNEXT</label>
          <a-select
            style="width: 100%; margin: 10px 0 10px 0"
            :allowClear="true"
            v-model="data.stynextLevelId"
            placeholder="Vui lòng chọn bậc stynext mới"
          >
            <a-select-option
              v-for="level in stynextLevelConfigOptions"
              :disabled="setDisabled(localData, level)"
              :key="level.id"
            >{{ level.name }}</a-select-option>
          </a-select>
        </a-col>
        <a-col
          v-if="localData.isGroupLeader && stynextGroupSelected && !stynextGroupSelected.groupLeaderId"
        >
          <a-checkbox @change="onChangeGroupLeader">Trưởng nhóm mới</a-checkbox>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button-group>
          <a-button @click="onClose">{{ $t('cancel') }}</a-button>
          <a-button
            @click="saveChange()"
            type="primary"
            :disabled="stynextGroupId === data.stynextGroupId || !data.salonId || data.salonId < 0 || !stynextGroupId"
          >{{ $t('save') }}</a-button>
        </a-button-group>
      </template>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { getFactory } from '@/api'
import { confirm, normalize } from '@/utils'
import { mixin, mixinDevice } from '@/utils/mixin'
import { mapGetters } from 'vuex'
const StynextGroupRepository = getFactory('stynextGroup')
const StynextGroupMemberRepository = getFactory('stynextGroupMember')
const StynextLevelConfigRepository = getFactory('stynextLevelConfig')

export default {
  mixins: [mixin, mixinDevice],
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    ...mapGetters(['user']),
    getMessage() {
      const salon = this.user.listSalon.find(x => x.id === this.localData.salonId)
      return (
        <p>
          Bạn đang thực hiện chuyển nhóm nhân viên: <b>{this.localData.staffName}. </b>
          Vị trí hiện tại: <b> {this.localData.staffType}</b> từ nhóm <b>{this.data.stynextGroupId}</b>{' '}
          <b>({salon.salonName}) </b>
          sang nhóm {this.stynextGroupSelected ? <b>{this.stynextGroupSelected.id}</b> : '...'}{' '}
          {this.salonSelected ? <b>({this.salonSelected.salonName})</b> : ''}
        </p>
      )
    },
    getMessageUpdateLevel() {
      return (
        <p>
          Khi chuyển sang nhóm mới <b>{this.localData.staffName}</b> sẽ có vai trò là <b>{this.localData.staffType}</b>.
          Vui lòng cập nhật lại<b> bậc STYNEXT</b> đúng với vai trò.
        </p>
      )
    }
  },

  watch: {
    salonId: val => {
      console.log(val)
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      isSelectedGroupLeader: false,
      groupLeaderId: -1,
      stynextGroupOptions: [],
      salonId: '',
      stynextGroupId: null,
      localData: null,
      salonSelected: undefined,
      stynextGroupSelected: undefined,
      stynextLevelConfigOptions: [],
      data: {}
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    stynextGroupMemeber: {
      type: Object,
      required: true
    }
  },
  created() {
    console.log('d322', this.stynextGroupMemeber)
    if (this.stynextGroupMemeber) {
      const cloneData = JSON.parse(JSON.stringify(this.stynextGroupMemeber))
      this.localData = cloneData
      console.log(this.localData)
       const cloneData2= JSON.parse(JSON.stringify(this.stynextGroupMemeber))
      this.data = cloneData2;
    }
    this.getStynextLevelConfig()
    this.handleChangeSalon(this.data.salonId)
  },

  methods: {
    showDrawer() {
      this.visible = true
    },
    onClose(isRequiredReload = false) {
      this.localVisible = false
      this.$emit('handleOnCloseDrawer', isRequiredReload)
      this.resetForm()
    },

    handleChangeSalon(value) {
      this.resetForm()
      if (!value) {
        return
      }
      this.salonSelected = this.user.listSalon.find(x => x.id === value)

      StynextGroupRepository.getCustomPath('select', { salonId: value }).then(res => {
        this.stynextGroupOptions = res
      })
    },

    async saveChange(record) {
      if (this.stynextGroupId === this.data.stynextGroupId) {
        this.$message.error('Nhóm đã chọn trùng với nhóm cũ')
        return
      }

      let stynextLevelConfig = this.stynextLevelConfigOptions.find(x => x.id === this.data.stynextLevelId)
      if (
        this.localData.isGroupLeader &&
        !this.isSelectedGroupLeader &&
        this.localData.stynextLevelId === this.data.stynextLevelId &&
        stynextLevelConfig.type != 0
      ) {
        this.$message.error('Vui lòng thay đổi bậc trước khi lưu lại.')
        return
      }

      const isConfirm = await confirm(this.$confirm, {
        title: `${this.$t('confirm')}`,
        content: `${this.$t('stynextGroup.confirmGroupMemberMove')}`
      })

      if (!isConfirm) {
        return
      }

      try {
        let { salonId } = this.data
        let stynextGroupId = this.stynextGroupId
        let params = { salonId, stynextGroupId }
        if(this.isSelectedGroupLeader){
          params = {...params, ...{isGroupLeader: true}}
        }
        else if (!this.isSelectedGroupLeader && this.localData.isGroupLeader) {
          params = {...params, ...{stynextLevelId: this.data.stynextLevelId}}
        }
        await StynextGroupMemberRepository.update(this.data.id, params)
        //bug: overflow: hidden body
        setTimeout(() => {
          this.onClose(true)
        }, 1000)
      } catch (e) {
        console.log(e)
      }
    },
    filterOptionSalon(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    filterOptionMember(input, option) {
      const otionText = option.componentOptions.children[0].text.toLowerCase()
      input = input.toLowerCase()
      return normalize(otionText).indexOf(normalize(input)) > 0
    },

    resetForm() {
      this.stynextGroupId = null
      this.stynextGroupOptions = []
    },

    selectStynextGroup(value) {
      this.stynextGroupSelected = this.stynextGroupOptions.find(x => x.id === value)
    },

    onChangeGroupLeader(e) {
      this.isSelectedGroupLeader = e.target.checked
    },

    getStynextLevelConfig() {
      this.stynextLevelConfigOptions = []
      StynextLevelConfigRepository.get().then(response => {
        this.stynextLevelConfigOptions = response
      })
    },

    setDisabled(record, stynextLevelConfig) {
      let isDisabled = false
      if (
        record.staffTypeId !== stynextLevelConfig.staffType ||
        (this.isSelectedGroupLeader && stynextLevelConfig.type != 1) ||
        (!this.isSelectedGroupLeader && stynextLevelConfig.type != 0)
      ) {
        isDisabled = true
      }
      return isDisabled
    }
  }
}
</script>
