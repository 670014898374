<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :xxl="4" :xl="8" :lg="8" :md="12" :sm="24">
            <a-form-item>
              <a-select
                showSearch
                :allowClear="true"
                @change="selectSalonSearch"
                :placeholder="$t('select.salon')"
                :filterOption="filterOptionSalon"
              >
                <a-select-option
                  v-for="salon of user.listSalon"
                  :value="salon.id"
                  :key="salon.id"
                >{{ salon.salonName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="4" :xl="8" :lg="8" :md="12" :sm="24" v-show="false">
            <a-form-item>
              <a-select
                showSearch
                :allowClear="true"
                @change="selectSalonSearch"
                :placeholder="$t('select.salon')"
                :filterOption="filterOptionSalon"
                v-model="queryParam.staffId"
              >
                <a-select-option
                  v-for="staff of staffOptions"
                  :value="staff.id"
                  :key="staff.id"
                >{{ staff.id + " - " + staff.fullName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="8" :xl="8" :lg="8" :md="12" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button
                type="default"
                icon="search"
                @click="getData"
                :disabled="queryParam.salonId <= 0"
              >
                {{
                $t('search')
                }}
              </a-button>
              <a-button
                v-if="$can($permission.add, $route.path)"
                type="primary"
                icon="add"
                @click="handleAddStynextGroup"
                style="margin-left: 10px"
              >{{ $t('add') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
      :rowKey="record => record.id"
      :columns="columns"
      :dataSource="dataSource.data"
      :pagination="false"
      :loading="loading"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a-button
          v-if="$can($permission.edit, $route.path)"
          type="primary"
          ghost
          @click="handleEdit(record)"
        >
          {{
          $t('edit')
          }}
        </a-button>
        <a-button
          v-if="$can($permission.delete, $route.path)"
          style="margin-left:3px"
          type="danger"
          ghost
          @click="handleDelete(record)"
        >{{ $t('delete') }}</a-button>
      </span>
      >
      <a-table
        slot-scope="source"
        slot="expandedRowRender"
        :columns="innerColumns"
        :dataSource="source.stynextGroupMembers"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <span slot="staffType" slot-scope="text, record">
          <a-tag
            :color="record.isGroupLeader ? 'volcano' : record.staffType == 'Stylist' ? 'geekblue' : 'green'"
          >{{ record.staffType }}</a-tag>
        </span>
        <span slot="isGroupLeader" slot-scope="text, record">
          <a-badge v-if="record.isGroupLeader == 1" status="success" />
          <a-badge v-else status="warning" />
          {{ positions[record.isGroupLeader] }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button
            v-if="$can($permission.edit, $route.path)"
            type="primary"
            ghost
            @click="handleMoveMember(record)"
          >
            {{
            $t('stynextGroupMember.moveMember')
            }}
          </a-button>
        </span>
      </a-table>
    </a-table>

    <pagination
      v-show="dataSource.total > 0"
      :total="dataSource.total"
      :page.sync="queryParam.page"
      :limit.sync="queryParam.limit"
      @pagination="getData"
    />

    <stynext-group-member-move
      v-if="visibleMoveMember"
      :stynextGroupMemeber="stynextGroupMemberItem"
      :visible.sync="visibleMoveMember"
      @handleOnCloseDrawer="handleOnCloseDrawer($event)"
    />
    <stynext-group-add
      v-if="visibleAddStynextGroup"
      :visible.sync="visibleAddStynextGroup"
      @handleOnCloseDrawer="handleOnCloseDrawer($event)"
    />
    <stynext-group-update
      v-if="visibleUpdateStynextGroup"
      :visible.sync="visibleUpdateStynextGroup"
      :data="stynextGroup"
      @handleOnCloseDrawer="handleOnCloseDrawer($event)"
    />
  </a-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from 'vuex'
import { mixin, mixinDevice } from '@/utils/mixin'
import { confirm } from '@/utils'
import Pagination from '@/components/Pagination'
import StynextGroupMemberMove from './StynextGroupMemberMove'
import StynextGroupAdd from './StynextGroupAdd'
import StynextGroupUpdate from './StynextGroupUpdate'

import { getFactory } from '@/api'
const StynextGroupRepository = getFactory('stynextGroup')
const StynextGroupMemberRepository = getFactory('stynextGroupMember')
const StaffRepository = getFactory('staff')

export default {
  name: 'StynextGroup',
  mixins: [mixin, mixinDevice],
  // eslint-disable-next-line vue/no-unused-components
  components: { Pagination, StynextGroupMemberMove, StynextGroupAdd, StynextGroupUpdate },
  computed: {
    ...mapGetters(['user'])
  },
  created() {},
  data() {
    return {
      loading: false,
      queryParam: {
        salonId: -1,
        page: 1,
        limit: 10
      },
      columns: [
        {
          title: this.$t('stynextGroup.id'),
          dataIndex: 'id'
        },
        {
          title: this.$t('stynextGroup.groupLeaderName'),
          dataIndex: 'groupLeaderName'
        },
        {
          title: this.$t('createdTime'),
          dataIndex: 'createdTime',
          scopedSlots: { customRender: 'createdTime' }
        },
        {
          title: this.$t('action'),
          dataIndex: 'type',
          scopedSlots: { customRender: 'action' },
          align: 'center'
        }
      ],
      innerColumns: [
        {
          title: this.$t('stynextGroupMember.staffId'),
          dataIndex: 'staffId'
        },
        {
          title: this.$t('stynextGroupMember.staffName'),
          dataIndex: 'staffName'
        },
        {
          title: this.$t('stynextGroup.staffType'),
          scopedSlots: { customRender: 'staffType' }
        },
        {
          title: this.$t('stynextGroupMember.isGroupLeader'),
          dataIndex: 'isGroupLeader',
          scopedSlots: { customRender: 'isGroupLeader' }
        },
        {
          title: this.$t('stynextGroupMember.stynextLevelName'),
          dataIndex: 'stynextLevelName',
        },
        {
          title: this.$t('action'),
          scopedSlots: { customRender: 'action' },
          align: 'center'
        }
      ],
      dataSource: {
        data: [],
        total: 0
      },
      innerData: [],
      positions: {
        true: 'Trưởng nhóm',
        false: 'Thành viên'
      },
      visibleMoveMember: false,
      visibleAddStynextGroup: false,
      visibleUpdateStynextGroup: false,
      stynextGroup: {},
      stynextGroupMemberItem: {},
      staffOptions: []
    }
  },

  methods: {
    async getData() {
      try {
        if (!this.queryParam || !this.queryParam.salonId) {
          return
        }
        this.loading = true
        this.dataSource = {
          data: [],
          total: 0
        }
        const res = await StynextGroupRepository.get(this.queryParam)
        this.dataSource.data = res.results
        this.dataSource.total = res.total
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    async handleEdit(record) {
      this.stynextGroup = {}
      this.visibleUpdateStynextGroup = true
      const res = await StynextGroupMemberRepository.get({ stynextGroupId: record.id })
      this.stynextGroup = { ...record, ...{ stynextGroupMembers: res } }
    },

    async handleDelete(record) {
      const isConfirm = await confirm(this.$confirm, {
        title: this.$t('confirm'),
        content: 'Bạn có thực sự muốn xóa nhóm này không?'
      })

      if (!isConfirm) {
        return
      }

      try {
        await StynextGroupRepository.deleteItem(record.id)
        this.$message.success('Xóa nhóm thành công.')
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },

    handleMoveMember(record) {
      console.log("record", record)
      this.stynextGroupMemberItem = record
      this.visibleMoveMember = true
    },

    handleAddStynextGroup() {
      this.visibleAddStynextGroup = true
    },

    handleOnCloseDrawer(value) {
      console.log('value', value)
      if (value === true) {
        this.getData(this.queryParam)
      }
    },

    selectSalonSearch(value) {
      this.queryParam.salonId = value
      StaffRepository.get({ salonId: value }).then(res => {
        this.staffOptions = res
      })
    },

    filterOptionSalon(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>
