<template>
  <div>
    <a-drawer
      :title="$t('stynextGroup.title.update')"
      :width="isMobile() ? 320 : 900"
      @close="onClose"
      :visible="localVisible"
      :maskClosable="false"
      :wrapStyle="{ height: 'calc(100% - 20px)', overflow: 'auto', paddingBottom: '20px' }"
    >
      <a-form layout="vertical" hideRequiredMark>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Salon">
              <a-select
                :allowClear="true"
                v-model="data.salonId"
                :placeholder="$t('select.salon')"
                disabled
              >
                <a-select-option
                  v-for="salon in user.listSalon"
                  :key="salon.id"
                >{{ salon.salonName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item :label="$t('staff.title')">
              <a-select
                showSearch
                :allowClear="true"
                @change="handleChange"
                :placeholder="$t('select.member')"
                :filterOption="filterOptionMember"
              >
                <a-select-option v-for="(staff, index) in staffOptions" :key="index">
                  {{
                  `${staff.fullName} (${staff.staffType})`
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-table
              :columns="columns"
              :dataSource="data.stynextGroupMembers"
              :rowKey="(record, index) => index"
              :pagination="false"
            >
              <span slot="isGroupLeader" slot-scope="text, record">
                <a-checkbox
                  @change="onChangeCheckBox($event, record)"
                  :checked="record.isGroupLeader"
                  :key="record.id"
                  :disabled="record.staffTypeId !== 1"
                />
              </span>
              <span slot="allowViewGroup" slot-scope="text, record">
                <a-checkbox
                  @change="onChangeCheckBoxPermView($event, record)"
                  :checked="record.allowViewGroup"
                  :key="record.id"
                  :disabled="record.staffTypeId !== 1"
                />
              </span>
              <span slot="position" slot-scope="text, record">
                <a-tag
                  :color="record.isGroupLeader ? 'volcano' : record.staffType == 'Stylist' ? 'geekblue' : 'green'"
                >{{ record.isGroupLeader ? $t('stynextGroup.groupLeaderName') : record.staffType }}</a-tag>
              </span>
              <span slot="action" slot-scope="text, record">
                <a-button type="danger" icon="delete" @click="removeMember(record)" />
              </span>
            </a-table>
          </a-col>
        </a-row>
      </div>
    </a-drawer>
    <stynext-level-update-dialog
      v-if="visibleUpdateLevelDialog"
      :visible.sync="visibleUpdateLevelDialog"
      :stynext-members="dataUpdate"
      :type.sync="updateLevelType"
      @handleOnClose="handleOnCloseDialog"
    ></stynext-level-update-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { getFactory } from '@/api'
import { mixin, mixinDevice } from '@/utils/mixin'
import { confirm, normalize } from '@/utils'
import { mapGetters } from 'vuex'
import StynextLevelUpdateDialog from './StynextLevelUpdateDialog'

const StaffRepository = getFactory('staff')
const StynextGroupRepository = getFactory('stynextGroup')
const StynextGroupMemberRepository = getFactory('stynextGroupMember')

export default {
  mixins: [mixin, mixinDevice],
  components: { StynextLevelUpdateDialog },
  computed: {
    ...mapGetters(['user']),
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  data() {
    return {
      columns: [
        {
          title: 'Mã nhân viên',
          dataIndex: 'staffId'
        },
        {
          title: 'Tên nhân viên',
          dataIndex: 'staffName'
        },
        {
          title: 'Vai trò',
          scopedSlots: { customRender: 'position' }
        },
        {
          title: 'Trưởng nhóm',
          scopedSlots: { customRender: 'isGroupLeader' }
        },
        {
          title: 'Quyền như TN',
          scopedSlots: { customRender: 'allowViewGroup' }
        },
        {
          title: 'Hành động',
          scopedSlots: { customRender: 'action' }
        }
      ],
      groupMembers: [],
      isSelectedGroupLeader: false,
      groupLeaderId: -1,
      staffOptions: [],
      salonId: null,
      requireLoading: false,
      addGroupMember: [],
      visibleUpdateLevelDialog: false,
      dataUpdate: [],
      updateLevelType: undefined
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  watch: {
    'data.salonId': function(value) {
      if (value) {
        StaffRepository.get({ salonId: value }).then(res => {
          this.staffOptions = res
        })
      }
    }
  },
  async created() {},
  methods: {
    showDrawer() {
      this.visible = true
    },
    onClose(requireLoading = true) {
      this.localVisible = false
      this.resetForm()
      this.$emit('handleOnCloseDrawer', requireLoading)
    },

    async handleChange(index) {
      console.log(index)
      const member = this.staffOptions[index]
      const isDuplicate = this.data.stynextGroupMembers.some(x => x.staffId == member.id)
      if (isDuplicate) {
        this.$message.error('Đã tồn tại thành viên này trong nhóm.')
        return
      }
      const isConfirmAdd = await confirm(this.$confirm, {
        title: this.$t('confirm'),
        content: 'Bạn có muốn thêm thành viên này vào nhóm không?'
      })

      if (!isConfirmAdd) {
        return
      }
      this.addMemberToGroup(member)
    },

    async addMemberToGroup(member, isGroupLeader = false) {
      const params = {
        salonId: this.data.salonId,
        staffId: member.id,
        stynextGroupId: this.data.id
      }
      try {
        await StynextGroupMemberRepository.create(params)
        const memberAdd = {
          staffId: member.id,
          salonId: member.salonId,
          staffName: member.fullName,
          staffType: member.staffType,
          isGroupLeader,
          staffTypeId: member.staffTypeId
        }
        this.data.stynextGroupMembers.push(memberAdd)
        await this.getStynextMember()
        this.$message.success('Thêm mới thành viên vào nhóm thành công.')
      } catch (error) {
        console.log(error)
      }
    },

    async removeMember(record) {
      console.log('record', record)
      const isConfirm = await confirm(this.$confirm, {
        title: this.$t('confirm'),
        content: 'Bạn có chắc chắn muốn xóa thành viên này khỏi nhóm không?'
      })
      if (!isConfirm) {
        return
      }

      try {
        if (record.isGroupLeader) {
          this.dataUpdate = []
          this.dataUpdate.push(record)
          this.updateLevelType = 'deleteGroupLeader'
          this.visibleUpdateLevelDialog = true
        } else {
          await StynextGroupMemberRepository.deleteItem(record.id)
          this.addGroupMember = this.groupMembers.filter(x => x.staffId != record.staffId)
          this.data.stynextGroupMembers = this.data.stynextGroupMembers.filter(x => x.staffId != record.staffId)
          this.$message.success('Xóa thành viên khỏi nhóm thành công.')
        }
      } catch (error) {
        console.log(error)
      }
    },
    resetForm() {
      this.groupMembers = []
      this.staffOptions = []
      this.isSelectedGroupLeader = false
      this.groupLeaderId = -1
    },
    async onChangeCheckBox(e, record) {
      try {
        console.log(record)
        const checked = e.target.checked
        console.log(checked)
        const texts = {
          true: 'Bạn có chắc chắn muốn chuyển THÀNH VIÊN thành TRƯỞNG NHÓM không?',
          false: 'Bạn có chắc chắn muốn chuyển TRƯỞNG NHÓM thành THÀNH VIÊN không?'
        }
        const isConfirm = await confirm(this.$confirm, {
          title: this.$t('confirm'),
          content: texts[!record.isGroupLeader]
        })
        if (!isConfirm) {
          return
        }
        this.dataUpdate = []
        //Lay thong tin thanh vien dang la truong nhom
        let memberLeader = this.data.stynextGroupMembers.find(x => x.isGroupLeader === true)
        if (memberLeader) {
          let record0 = Object.assign({}, memberLeader)
          record0.positionLast = false
          record0.positionOld = this.dataUpdate.push(record0)
        }

        if (!memberLeader || memberLeader.id !== record.id) {
          let record1 = Object.assign({}, record)
          record1.positionLast = true
          this.dataUpdate.push(record1)
        }

        this.visibleUpdateLevelDialog = true
      } catch (error) {
        console.log(error)
      }
    },

    async onChangeCheckBoxPermView(e, record) {
      try {
        const checked = e.target.checked
        const texts = {
          true: ('Bạn có chắc chắn muốn cho phép thành viên này có QUYỀN xem như TRƯỞNG NHÓM không?'),
          false: 'Bạn có chắc chắn muốn hủy QUYỀN xem như TRƯỞNG NHÓM của thành viên này không?'
        }
        const isConfirm = await confirm(this.$confirm, {
          title: this.$t('confirm'),
          content: texts[!record.allowViewGroup]
        })
        console.log(isConfirm)
        if (!isConfirm) {
          return
        }
        await StynextGroupMemberRepository.updatePermissionViewInfo(record.id);
        this.$message.success("Cập nhật thành công.")
        await this.getStynextMember();
      } catch (error) {
        console.log(error)
      }
    },

    async getStynextMember() {
      const res = await StynextGroupMemberRepository.get({ stynextGroupId: this.data.id })
      this.data.stynextGroupMembers = res
    },

    filterOptionMember(input, option) {
      const otionText = option.componentOptions.children[0].text.toLowerCase()
      input = input.toLowerCase()
      return normalize(otionText).indexOf(normalize(input)) > 0
    },
    handleOnCloseDialog(value) {
      if (value === true) {
        this.getStynextMember(this.queryParam)
      }
    }
  }
}
</script>
