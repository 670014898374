<template>
  <div>
    <a-drawer
      title="Thêm mới nhóm STYNEXT"
      :width="isMobile() ? 320 : 720"
      @close="onClose"
      :visible="localVisible"
      :maskClosable="false"
      :wrapStyle="{ height: 'calc(100% - 108px)', overflow: 'auto', paddingBottom: '108px' }"
    >
      <a-form layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Salon">
              <a-select
                showSearch
                :allowClear="true"
                v-model="salonId"
                @change="handleChangeSalon"
                :placeholder="$t('select.salon')"
                :filterOption="filterOptionSalon"
              >
                <a-select-option v-for="salon in user.listSalon" :key="salon.id">{{ salon.salonName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item :label="$t('staff.title')">
              <a-select
                showSearch
                :allowClear="true"
                @change="handleChangeStaff"
                v-model="selectedStaff"
                :placeholder="$t('select.member')"
                :disabled="!salonId || salonId < 0"
                :filterOption="filterOptionMember"
              >
                <a-select-option v-for="(staff, index) in staffOptions" :key="index">{{
                  `${staff.fullName} (${staff.staffType})`
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24" v-show="!isSelectedGroupLeader">
            <a-alert :message="$t('stynextGroup.requiredSelectGroupLeader')" type="warning" />
          </a-col>
        </a-row>
      </a-form>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-table
            :columns="columns"
            :dataSource="stynextGroupMembers"
            :rowKey="(record, index) => index"
            :pagination="false"
          >
            <span slot="position" slot-scope="text, record">
              <a-tag :color="record.isGroupLeader ? 'volcano' : record.staffType == 'Stylist' ? 'geekblue' : 'green'">{{
                record.isGroupLeader ? 'Trưởng nhóm' : record.staffType
              }}</a-tag>
            </span>
            <span slot="isGroupLeader" slot-scope="text, record">
              <a-checkbox
                @change="onChangeCheckBox($event, record)"
                :checked="record.isGroupLeader"
                :key="record.id"
                :disabled="record.staffTypeId !== 1"
              />
            </span>
            <span slot="action" slot-scope="record">
              <a-button type="danger" icon="delete" @click="removeMember(record)" />
            </span>
          </a-table>
        </a-col>
      </a-row>
      <div
        :style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right'
        }"
      >
        <a-button-group>
          <a-button @click="onClose">{{ $t('cancel') }}</a-button>
          <a-button @click="addGroup(false)" type="primary" :disabled="!isSelectedGroupLeader">{{
            $t('save')
          }}</a-button>
          <a-button @click="addGroup" type="primary" :disabled="!isSelectedGroupLeader">{{
            $t('saveAndContinue')
          }}</a-button>
        </a-button-group>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { getFactory } from '@/api'
import { normalize } from '@/utils'
import { mixin, mixinDevice } from '@/utils/mixin'
import { mapGetters } from 'vuex'
const StaffRepository = getFactory('staff')
const StynextGroupRepository = getFactory('stynextGroup')
const StynextGroupMemberRepository = getFactory('stynextGroupMember')

const columns = [
  {
    title: 'Mã nhân viên',
    dataIndex: 'staffId'
  },
  {
    title: 'Tên nhân viên',
    dataIndex: 'staffName'
  },
  {
    title: 'Vai trò',
    scopedSlots: { customRender: 'position' }
  },
  {
    title: 'Trưởng nhóm',
    dataIndex: 'isGroupLeader',
    scopedSlots: { customRender: 'isGroupLeader' }
  },
  {
    title: '',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  mixins: [mixin, mixinDevice],
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        console.log(val)
        this.$emit('update:visible', val)
      }
    },
    ...mapGetters(['user'])
  },
  watch: {
    salonId: val => {
      console.log(val)
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      columns,
      stynextGroupMembers: [],
      isSelectedGroupLeader: false,
      groupLeaderId: -1,
      staffOptions: [],
      salonId: '',
      requireLoading: false,
      selectedStaff: null
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  created() {
    console.log(this.salonId)
  },

  methods: {
    showDrawer() {
      this.visible = true
    },
    onClose(requireLoading = false) {
      this.localVisible = false
      this.$emit('handleOnCloseDrawer', requireLoading)
      this.resetForm()
    },

    async handleChangeStaff(index) {
      if (index == undefined) {
        return
      }

      const member = this.staffOptions[index]
      const isDuplicate = this.stynextGroupMembers.some(x => x.staffId == member.id)
      if (isDuplicate) {
        this.$message.error('Đã tồn tại thành viên này trong nhóm.')
        return
      }
      const isStaffExistInGroup = await this.isStaffExistInGroup({ staffId: this.staffOptions[index].id })
      if (isStaffExistInGroup) {
        this.$message.error('Nhân viên này đã tồn tại ở một nhóm khác.')
        return
      }
      this.addMemberToGroup(member, false)
    },

    isStaffExistInGroup(staffId) {
      return new Promise(resolve => {
        StynextGroupMemberRepository.checkExistInGroup(staffId).then(res => {
          if (res > 0) {
            return resolve(true)
          }
          return resolve(false)
        })
      })
    },

    addMemberToGroup(member, isGroupLeader = false) {
      this.stynextGroupMembers.push({
        staffId: member.id,
        salonId: member.salonId,
        staffName: member.fullName,
        staffType: member.staffType,
        isGroupLeader,
        staffTypeId: member.staffTypeId
      })
    },

    handleChangeSalon(value) {
      this.resetForm()
      if (!value) {
        return
      }
      StaffRepository.get({ salonId: value }).then(res => {
        this.staffOptions = res
      })
    },

    removeMember(record) {
      this.stynextGroupMembers = this.stynextGroupMembers.filter(x => x.staffId != record.staffId)
      if (record.isGroupLeader) {
        this.isSelectedGroupLeader = false
      }
    },
    addGroup(isContinue = false) {
      if (!this.isSelectedGroupLeader) {
        this.$message.error('Bạn chưa chọn trưởng nhóm. Vui lòng chọn trước nhóm để tạo nhóm.')
        return
      }
      const payload = {
        salonId: this.salonId,
        groupLeaderId: this.groupLeaderId,
        stynextGroupMembers: this.stynextGroupMembers
      }
      StynextGroupRepository.create(payload)
        .then(() => {
          this.$message.success('Thêm mới thành công.')
          if (!isContinue) {
            this.onClose(true)
          }
          this.resetForm()
        })
        .catch(e => {
          console.log(e)
        })
    },
    resetForm() {
      this.stynextGroupMembers = []
      this.isSelectedGroupLeader = false
      this.groupLeaderId = -1
      this.selectedStaff = null
    },
    onChangeCheckBox(e, record) {
      const checked = e.target.checked
      if (this.isSelectedGroupLeader) {
        const groupMember = this.stynextGroupMembers.find(x => x.isGroupLeader === true)
        if (groupMember) {
          groupMember.isGroupLeader = !checked
        }
      }
      this.groupLeaderId = checked ? record.staffId : -1
      record.isGroupLeader = checked
      this.isSelectedGroupLeader = checked
    },
    filterOptionSalon(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    filterOptionMember(input, option) {
      const otionText = option.componentOptions.children[0].text.toLowerCase()
      input = input.toLowerCase()
      return normalize(otionText).indexOf(normalize(input)) > 0
    }
  }
}
</script>
