<template>
  <div>
    <a-modal :visible="localVisible" title="Cập nhật thông tin bậc stynext" :width="1000" @cancel="handleCancel">
      <a-table :columns="columns" :dataSource="stynextMembers" :rowKey="(record, index) => index" :pagination="false">
        <span slot="stynextLevel" slot-scope="text, record">
          <a-select style="width: 150px" v-model="record.stynextLevelId" placeholder="Vui lòng chọn bậc stynext mới">
            <a-select-option
              v-for="level in stynextLevelConfigOptions"
              :disabled="setDisabled(record, level)"
              :key="level.id"
              >{{ level.name }}</a-select-option
            >
          </a-select>
        </span>
        <span slot="positionOld" slot-scope="text, record">
          <a-tag color="volcano">{{
            record.isGroupLeader ? $t('stynextGroup.groupLeaderName') : record.staffType
          }}</a-tag>
        </span>
        <span slot="positionLast" slot-scope="text, record">
          <a-tag color="green">{{ record.positionLast ? $t('stynextGroup.groupLeaderName') : record.staffType }}</a-tag>
        </span>
      </a-table>
      <a-alert
        :message="getMessage"
        type="warning"
        style="margin-top:10px; text-align: center"
      />
      <template slot="footer">
        <a-button key="back" @click="handleCancel">Hủy bỏ</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">Lưu lại</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { getFactory } from '@/api'
import { confirm } from '@/utils'
const StynextGroupMemberRepository = getFactory('stynextGroupMember')
const StynextLevelConfigRepository = getFactory('stynextLevelConfig')

export default {
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    localType:{
       get() {
        return this.type
      },
      set(val) {
        this.$emit('update:type', val)
      }
    },
    getMessage(){
      let message ="Vui lòng cập nhật lại bậc STYNEXT của nhân viên để cập nhật lại thông tin trưởng nhóm stynext"
      if(this.type === 'deleteGroupLeader'){
         message ="Vui lòng cập nhật lại bậc STYNEXT để xóa trưởng nhóm khỏi nhóm"
      }
      return message
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    stynextMembers: {
      type: Array,
      default: new Array()
    },
    type: {
      type: String,
      default: "updateStynextGroupLeader"
    }
  },
  data() {
    return {
      columns: [
        {
          title: 'Mã nhân viên',
          dataIndex: 'staffId'
        },
        {
          title: 'Tên nhân viên',
          dataIndex: 'staffName'
        },
        {
          title: 'Vai trò cũ',
          scopedSlots: { customRender: 'positionOld' }
        },
        {
          title: 'Vai trò mới',
          scopedSlots: { customRender: 'positionLast' }
        },
        {
          title: 'Bậc Stynext cũ',
          dataIndex: 'stynextLevelName'
        },
        {
          title: 'Bậc Stynext mới',
          scopedSlots: { customRender: 'stynextLevel' }
        }
      ],
      loading: false,
      stynextLevelConfigOptions: [],
      localData: []
    }
  },
  async created() {
    if (this.stynextMembers) {
      const cloneData = JSON.parse(JSON.stringify(this.stynextMembers))
      this.localData = cloneData
    }
    await this.getStynextLevelConfig()
  },
  methods: {
    handleCancel(requireLoading = false) {
      this.localVisible = false
      this.localType = undefined
      this.$emit('handleOnClose', requireLoading)
    },
    async handleOk() {
      try {
        let isNotChange = false
        for (let item of this.localData) {
          let itemChange = this.stynextMembers.find(x => x.stynextLevelId == item.stynextLevelId && x.id === item.id)
          //Lay thong tin level moi cua member
          if (itemChange) {
            let stynextLevelConfig = this.stynextLevelConfigOptions.find(x => x.id === itemChange.stynextLevelId)
            if (
              (itemChange.positionLast && stynextLevelConfig.type != 1) ||
              (!itemChange.positionLast && stynextLevelConfig.type != 0)
            ) {
              isNotChange = true
              break
            }
          }
        }
        if (isNotChange && this.localType!=='deleteGroupLeader') {
          this.$message.error('Vui lòng thay đổi bậc stynext trước khi lưu lại')
          return
        }

        const isConfirm = await this.isConfirm()
        if (!isConfirm) {
          return
        }
        
        if(this.localType === 'deleteGroupLeader'){
          await StynextGroupMemberRepository.deleteAndUpdateLevel(this.stynextMembers[0].id, {stynextLevelId: this.stynextMembers[0].stynextLevelId})
          this.$message.success('Xóa thành viên khỏi nhóm thành công.')
          this.handleCancel(true)
        } else {
           let params = []
          for (let item of this.stynextMembers) {
            params.push({
              id: item.id,
              stynextLevelId: item.stynextLevelId,
              isGroupLeader: item.positionLast
            })
          }
          await StynextGroupMemberRepository.putCustomPath(`leaders`, params)
          this.$message.success('Cập nhật thông tin trưởng nhóm thành công')
          this.handleCancel(true)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getStynextLevelConfig() {
      try {
        this.stynextLevelConfigOptions = []
        this.stynextLevelConfigOptions = await StynextLevelConfigRepository.get()
      } catch (error) {
        console.log(error)
      }
    },
    setDisabled(record, stynextLevelConfig) {
      let isDisabled = false
      if (
        record.staffTypeId !== stynextLevelConfig.staffType ||
        (record.positionLast && stynextLevelConfig.type != 1) ||
        (!record.positionLast && stynextLevelConfig.type != 0)
      ) {
        isDisabled = true
      }
      return isDisabled
    },
    async isConfirm(){
      let message ='Bạn có chắc chắn muốn cập nhật lại trưởng nhóm và bậc stynext không?';
      if(this.localType==='deleteGroupLeader'){
        message ='Bạn có chắc chắn muốn xóa trưởng nhóm và cập nhật lại bậc STYNEXT của nhân viên này không?';
      }
       const isConfirm = await confirm(this.$confirm, {
          title: this.$t('confirm'),
          content: message
        })
      return isConfirm;
    }
  }
}
</script>
